// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'ou_short_name', 'mgr_login_name']}
  },
  create (config, {ou_short_name_new, mgr_login_name_new}) {
    const params = { 'new': { 'ou_short_name': ou_short_name_new, 'mgr_login_name': mgr_login_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2ou/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'ou_short_name', 'mgr_login_name'], 'new': [ ]}
  },
  delete (config, {ou_short_name_old, mgr_login_name_old}) {
    const params = { 'new': { }, 'old': { 'ou_short_name': ou_short_name_old, 'mgr_login_name': mgr_login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2ou/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, is_own, is_sub, gpk_list, fetch_limit, fetch_offset, filter_params_dict, ou_short_name_list, mgr_login_name_list, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'is_own': is_own, 'is_sub': is_sub, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'filter_params_dict': filter_params_dict, 'ou_short_name_list': (ou_short_name_list === null) ? null : JSON.stringify(ou_short_name_list), 'mgr_login_name_list': (mgr_login_name_list === null) ? null : JSON.stringify(mgr_login_name_list), 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2ou/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'ou_short_name', 'mgr_login_name'], 'new': [ 'ou_short_name']}
  },
  update (config, {ou_short_name_old, ou_short_name_new, mgr_login_name_old}) {
    const params = { 'new': { 'ou_short_name': ou_short_name_new}, 'old': { 'ou_short_name': ou_short_name_old, 'mgr_login_name': mgr_login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2ou/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
